import './App.scss';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Tokenomics from './components/Tokenomics/Tokenomics';
import About from './components/About/About';
import Roadmap from './components/Roadmap/Roadmap';

function App() {
    return (
        <div className="App">
            <Header />
            <main className="main">
                <Home />
                <About />
                <Tokenomics />
                <Roadmap />
            </main>
            <Footer />
        </div>
    );
}

export default App;
