import { useEffect, useState } from 'react';
import { off, on } from '../../utils';
/**
 * useScrolling
 * @returns {boolean}
 */
export const useScrolling = () => {
    //if it is SSR then check you are now on the client and window object is available
    //let prevScroll;
    // if (process.browser) {
    //   prevScroll = window.pageYOffset
    // }

    const [scrollingUp, setScrollingUp] = useState(false);

    const handleScroll = () => {
        const currScroll = window.scrollY;
        // const isScrolled = prevScroll > currScroll;
        setScrollingUp(Boolean(currScroll));
        // prevScroll = currScroll;
    };

    useEffect(() => {
        on(window, 'scroll', handleScroll, { passive: true });
        return () => {
            off(window, 'scroll', handleScroll, { passive: true });
        };
    }, []);
    return scrollingUp;
};
