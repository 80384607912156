import React from 'react';
import styles from './styles.module.scss';
import { linksFooter } from '../../constants/links';
import LinkLogo from '../LinkLogo/LinkLogo';

const Footer = () => {
    return (
        <footer className={`section ${styles.footer}`}>
            <div className={styles.links}>
                {linksFooter.map((link, key) => (
                    <LinkLogo href={link.href} src={link.src} disabled={link.disabled} key={`foot-link-${key}`} />
                ))}
            </div>
            <div className={styles.textWrapper}>
                <p className="typography-description">
                    $SHREKE coin is neither affiliated with nor endorsed by Matt Furie, the creator of Pepe the Frog, nor with DreamWorks
                    Animation, the creator of Shrek, or any of their respective subsidiaries or affiliates. The names 'Pepe the Frog,' and
                    'Shrek,' as well as related names, marks, emblems, and images are registered trademarks of their respective owners. This
                    token pays homage to two widely recognized and beloved memes, crafted as a tribute to the unique characters and the
                    universal spirit of humor and adventure they represent.
                </p>
                <p className="typography-description">
                    $SHREKE is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap.
                    the coin is completely useless and for entertainment purposes only.
                </p>
            </div>
            <div className={styles.copyright}>© 2024 by SHREKE. All rights reserved!</div>
        </footer>
    );
};

export default Footer;
