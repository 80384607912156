import React from 'react';
import styles from './styles.module.scss';

const About = () => {
    return (
        <section id="about" className="section">
            <div className={`content ${styles.wrapper}`}>
                <h2 className="header-section">About SHREKE</h2>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <img src="/shreke_full.png" alt="Shreke" className={styles.shrekeFull} />
                    </div>
                    <div className={styles.right}>
                        <p className={`typography-body`}>
                            In the vibrant universe of memes, a new hero emerges: Shreke, the digital ogre. Born from the fusion of the
                            beloved swamp dweller, and Pepe, the iconic internet frog, Shreke symbolizes the playful heart of internet
                            culture and the daring spirit of cryptocurrency.
                        </p>
                        <p className={`typography-body`}>
                            Shreke is not just an ogre but a savvy navigator of the memecoin realms. His adventures, full of humor and
                            unexpected turns, echo the ups and downs of the crypto market. From battling internet trolls to seeking wisdom
                            in the digital wilderness, Shreke's journey is a wild ride through the heart of online creativity.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
