import React from 'react';
import styles from './styles.module.scss';
import { useScrolling } from './useScrolling';

const Header = () => {
    const isScrolled = useScrolling();

    return (
        <header className={`${styles.header} ${isScrolled ? styles.sticky : ''}`}>
            <div className={styles.header_content}>
                <a href="/">
                    <img src="/logo.png" className={styles.logo_big} alt="logo" />
                    <img src="/shreke_head.png" className={styles.logo_small} alt="logo" />
                </a>

                <nav className={styles.nav}>
                    <a href="#about" className={styles.link}>
                        About
                    </a>
                    <a href="#tokenomics" className={styles.link}>
                        Tokenomics
                    </a>
                    <a href="#roadmap" className={styles.link}>
                        Roadmap
                    </a>
                </nav>
            </div>
        </header>
    );
};

export default Header;
