import React from 'react';
import styles from './styles.module.scss';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip);

const Tokenomics = () => {
    return (
        <section id="tokenomics" className="section">
            <div className={`content ${styles.wrapper}`}>
                <h2 className="header-section">Tokenomics</h2>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <div className={styles.ulWrapper}>
                            <ul>
                                <li className={`typography-body`}>Supply: 420,000,000</li>
                                <li className={`typography-body`}>Presale: 50% (210,000,000)</li>
                                <li className={`typography-body`}>Liquidity: 25% (105,000,000)</li>
                                <li className={`typography-body`}>CEX listing: 15% (63,000,000)</li>
                                <li className={`typography-body`}>Marketing: 10% (42,000,000)</li>
                            </ul>
                            <ul>
                                <li className={`typography-body`}>0% Buy, Sell & Transfer Tax</li>
                                <li className={`typography-body`}>Ownership Renounced & LP Burned</li>
                                <li className={`typography-body`}>Chain: BSC (BINANCE SMART CHAIN)</li>
                                <li className={`typography-body`}>Launch method: PreSale On PinkSale</li>
                            </ul>
                        </div>
                        <a target="_blank" rel="noreferrer" href="https://coinsult.net/projects/shreke/">
                            <button className={'button-primary'}>Audit</button>
                        </a>
                    </div>
                    <div className={styles.right}>
                        <Doughnut
                            data={{
                                labels: ['Presale', 'Liquidity', 'CEX listing', 'Marketing'],
                                datasets: [
                                    {
                                        label: 'SHREKE Amount',
                                        data: [210_000_000, 105_000_000, 63_000_000, 42_000_000],
                                        backgroundColor: ['#286B2A', '#599D3C', '#52D156', '#94F697'],
                                        borderColor: 'black',
                                        borderWidth: 1,
                                    },
                                ],
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tokenomics;
