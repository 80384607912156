import React from 'react';
import styles from './styles.module.scss';
import { links } from '../../constants/links';
import LinkLogo from '../LinkLogo/LinkLogo';

const Home = () => {
    return (
        <section id="#" className="section">
            <div className={`content ${styles.wrapper}`}>
                <div className={styles.left}>
                    <h1 className={styles.name}>SHREKE</h1>
                    <p className="typography-body">
                        The most memeable memecoin in existence. The dogs have had their day, it’s time for Shreke to take reign.
                    </p>
                    <div className={styles.contract}>
                        <p className="typography-body">Contract address:</p>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://bscscan.com/token/0x537084d776ea17a7538f44a25b2e6c4c9f53a5e0"
                            className={`typography-body ${styles.address}`}
                        >
                            0x537084d776ea17a7538f44a25b2e6c4c9f53a5e0
                        </a>
                    </div>
                    <div className={styles.buttons}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.pinksale.finance/launchpad/0xBf6a2Cb0D3dB04971714C04E4dA30b4061D97939?chain=BSC"
                        >
                            <button className="button-primary">Buy on PinkSale</button>
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://coinsult.net/projects/shreke/">
                            <button className={'button-primary'}>Audit</button>
                        </a>
                    </div>
                    <div className={styles.links}>
                        {links.map((link, key) => (
                            <LinkLogo href={link.href} src={link.src} disabled={link.disabled} key={key} />
                        ))}
                    </div>
                </div>
                <div className={styles.right}>
                    <img src="/shreke_logo_head_tr.png" alt="shreke_head" className={styles['shreke_head']} />
                </div>
            </div>
        </section>
    );
};

export default Home;
