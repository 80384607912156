import React from 'react';
import styles from './styles.module.scss';

const Roadmap = () => {
    return (
        <section id="roadmap" className="section">
            <div className={`content ${styles.wrapper}`}>
                <h2 className="header-section">Roadmap</h2>
                <div className={styles.content}>
                    <div className={styles.phase}>
                        <h4 className={styles.phaseTitle}>Phase 1</h4>
                        <ul>
                            <li className={`typography-body`}>Smart Contract Audit</li>
                            <li className={`typography-body`}>Website Development</li>
                            <li className={`typography-body`}>Twitter Marketing Campaign</li>
                            <li className={`typography-body`}>Telegram Marketing Campaign</li>
                            <li className={`typography-body`}>Youtube Marketing Campaign</li>
                            <li className={`typography-body`}>PreSale On PinkSale</li>
                            <li className={`typography-body`}>CMC Fast Track</li>
                            <li className={`typography-body`}>Telegram and Twitter Calls</li>
                            <li className={`typography-body`}>PR at TOP1 Newsletter</li>
                            <li className={`typography-body`}>Pancake Listing</li>
                        </ul>
                    </div>
                    <div className={styles.phase}>
                        <h4 className={styles.phaseTitle}>Phase 2</h4>
                        <ul>
                            <li className={`typography-body`}>Massive Partnerships</li>
                            <li className={`typography-body`}>DexTools Trending</li>
                            <li className={`typography-body`}>DexScreener Trending</li>
                            <li className={`typography-body`}>MoonTok, CNToken, NTM.ai Listings</li>
                            <li className={`typography-body`}>CMC and CG Listing</li>
                            <li className={`typography-body`}>BscScan update</li>
                            <li className={`typography-body`}>1000+ Holders</li>
                            <li className={`typography-body`}>4000+ TG members</li>
                            <li className={`typography-body`}>Shreke NFTs</li>
                            <li className={`typography-body`}>Airdrop Campaign</li>
                        </ul>
                    </div>
                    <div className={styles.phase}>
                        <h4 className={styles.phaseTitle}>Phase 3</h4>
                        <ul>
                            <li className={`typography-body`}>TrustWallet Listing</li>
                            <li className={`typography-body`}>CEX listing</li>
                            <li className={`typography-body`}>Huge meme campaign</li>
                            <li className={`typography-body`}>5000+ holders</li>
                            <li className={`typography-body`}>7000+ Telegram members</li>
                            <li className={`typography-body`}>To be continued...</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Roadmap;
