export const links = [
    { href: 'https://twitter.com/shrekebsc', src: 'logos/x.png' },
    { href: 'https://t.me/shrekecoin', src: 'logos/telegram.webp' },
    { href: 'https://www.pinksale.finance/launchpad/0xBf6a2Cb0D3dB04971714C04E4dA30b4061D97939?chain=BSC', src: 'logos/pinksale.png' },
    { href: 'https://coinsult.net/projects/shreke', src: 'logos/coinsult_logo.svg' },
    { href: 'https://bscscan.com/token/0x537084d776ea17a7538f44a25b2e6c4c9f53a5e0', src: 'logos/bscscan.png' },
    { href: 'https://www.dexview.com/', src: 'logos/dexview.png' },
    // { src: 'logos/pancake.svg' },
    // { src: 'logos/cmp.svg' },
    // { src: 'logos/coingecko.svg' },
    // { src: 'logos/dexsc.svg' },
];

export const linksFooter = [
    { href: 'https://twitter.com/shrekebsc', src: 'logos/x.png' },
    { href: 'https://t.me/shrekecoin', src: 'logos/telegram.webp' },
    { href: 'https://www.pinksale.finance/', src: 'logos/pinksale.png' },
    { href: 'https://coinsult.net/projects/shreke', src: 'logos/coinsult_logo.svg' },
    { href: 'https://bscscan.com/token/0x537084d776ea17a7538f44a25b2e6c4c9f53a5e0', src: 'logos/bscscan.png' },
    { href: 'https://www.dexview.com/', src: 'logos/dexview.png' },
    // { src: 'logos/pancake.svg' },
    // { src: 'logos/cmp.svg' },
    // { src: 'logos/coingecko.svg' },
    // { src: 'logos/dexsc.svg' },
];
