import styles from './styles.module.scss';

const LinkLogo = ({ href, src }) => {
    return (
        <a href={href} target="_blank" rel="noreferrer">
            <img className={`${styles.linkLogo} ${href ? '' : 'disabled'}`} src={src} alt={src} />
        </a>
    );
};

export default LinkLogo;
